<script context="module">
	import { derived } from 'svelte/store';

	// Build a list of peers we can connect to (i.e. we're not already connected/ing to)
	const CONNECTABLE_PEERS = derived([PEERS, DEVICES_STATUS], ([$peers, $connected]) => {
		return $peers.filter(peer => !(peer.device.server_addr in $connected));
	});
</script>

<script>
	import { PEERS } from '../discovery';
	import { DEVICES_STATUS, DEVICE_ONLINE, FRIENDS } from '../friends';
	import DeviceListItem from './DeviceListItem.svelte';
	import { ACTIVE_DEVICE_SRC_DISCOVERY } from './constants';

	export let showDiscovery;
	export let showManualConnect;
	export let activeDevice;
</script>

<main>
	<section class="scroll-subtle">
		{#each $CONNECTABLE_PEERS as peer}
			<DeviceListItem status={DEVICE_ONLINE} device={peer.device} src={ACTIVE_DEVICE_SRC_DISCOVERY} {activeDevice} />
		{/each}
	</section>

	<div class="searching">
		<div><img src="img/wifi.svg" alt="" />&nbsp;Searching...</div>
		<div>If you can't find your device, try manually connecting to its IP address.</div>
	</div>

	<div class="manual-connect">
		<div class="pill-btn blue" on:click={() => showManualConnect(true)}>
			<img alt="" src="img/network.svg" />
			Connect Manually...
		</div>
	</div>

	{#if $FRIENDS.length > 0}
		<div class="cancel">
			<div class="pill-btn grey" on:click={() => showDiscovery(false)}>Cancel</div>
		</div>
	{/if}

	<div id="ripple">
		<div class="ripple ripple-1" />
		<div class="ripple ripple-2" />
		<div class="ripple ripple-3" />
		<div class="ripple ripple-4" />
		<div class="ripple ripple-5" />
	</div>
</main>

<style>
	main {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		/*
		dot background
		background-image: radial-gradient(#000000 1px, transparent 0);
		background-size: 8px 8px;
		background-position: 1px 1px;
		*/
	}
	main > section {
		padding: 1rem;
		overflow-y: auto;
		flex: 1;
	}

	#ripple {
		position: fixed;
		bottom: 0;
		left: calc((14rem - 25rem) / 2); /* 14rem = column size */
		width: 25rem;
		height: 25rem;
		pointer-events: none;
		z-index: -1;
	}
	.ripple {
		position: absolute;
		height: 150%;
		width: 150%;
		left: 0;
		bottom: 0;
		background-color: #000;
		border-radius: 50%;
		animation-name: ripple;
		animation-duration: 8s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-out;
		will-change: transform, opacity;
		z-index: -1;
	}
	.ripple-1 {
		animation-delay: -1.33333333s;
	}
	.ripple-2 {
		animation-delay: -2.66666666s;
	}
	.ripple-3 {
		animation-delay: -3.99999999s;
	}
	.ripple-4 {
		animation-delay: -5.33333332s;
	}
	.ripple-5 {
		animation-delay: -6.66666665s;
	}
	@keyframes ripple {
		0% {
			transform: translate(-16.6666667%, 50%) scale(0);
			opacity: 0.25;
		}
		100% {
			transform: translate(-16.6666667%, 50%) scale(1);
			opacity: 0;
		}
	}

	.searching {
		line-height: 1.6;
		text-align: center;
		padding: 1rem;
		padding-top: 0;
	}
	.searching img {
		height: 1em;
		vertical-align: middle;
	}
	.searching > div:last-child {
		margin-top: 0.35rem;
		font-size: 0.8em;
	}

	.cancel {
		padding: 1rem;
		padding-top: 0;
	}
	.manual-connect {
		padding: 1rem;
		padding-top: 0;
		font-size: 0.85em;
		text-align: center;
	}
</style>
