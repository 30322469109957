import { appWindow } from '@tauri-apps/api/window';
import { writable } from 'svelte/store';

export const PEERS = writable([]);

appWindow.listen('DiscoveryEvent::PeerFound', ({ payload }) => {
	console.log('Peer found', payload);
	PEERS.update(peers => {
		// const idx = peers.findIndex(cmp => cmp.device.server_addr === payload.device.server_addr);
		const idx = peers.findIndex(cmp => cmp.device.id === payload.device.id); // TODO allow user to use server_addr or id
		if (idx === -1) {
			peers.push(payload);
		} else {
			peers[idx] = payload;
		}
		return peers;
	});
});

appWindow.listen('DiscoveryEvent::PeerLost', ({ payload }) => {
	console.log('Lost peer', payload);
	PEERS.update(peers => {
		const idx = peers.findIndex(cmp => cmp.device.server_addr === payload);
		if (idx !== -1) peers.splice(idx, 1);
		return peers;
	});
});

appWindow.listen('DiscoveryEvent::Sync', ({ payload }) => {
	PEERS.set(payload);
});
