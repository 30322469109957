<script context="module">
	function humanFileSize(bytes, si = false, dp = 1) {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}

		const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(dp) + ' ' + units[u];
	}
</script>

<script>
	import { appWindow } from '@tauri-apps/api/window';
	import { onDestroy } from 'svelte';
	import { writable } from 'svelte/store';
	import { tippy } from './tippy';

	if (window.FILE_DROP == null) {
		appWindow.close();
	}

	let error_count = 0;

	const [started, indexed_paths, files, total_size, total_received, logs] = (() => {
		const indexed_paths = [];
		const files = [];

		let total_size = 0;

		const [started, paths, errors] = window.FILE_DROP;

		let file_id = 0;

		paths.forEach(([path, size]) => {
			total_size += size;

			const file = {
				id: file_id++,
				path,
				total_size: size,
				bytes_received: 0,
				error: null,
			};

			indexed_paths.push(file);
			files.push(file);
		});

		errors.forEach(([path, error]) => {
			error_count++;
			files.push({
				id: file_id++,
				path,
				error,
			});
		});

		return [started, indexed_paths, writable(files), total_size, writable(0), writable([])];
	})();

	let log_id = 0;
	function add_log(text, success) {
		logs.update(logs => {
			logs.unshift({
				id: log_id++,
				text,
				success: success === true,
				gc: new Date(),
			});
			return logs;
		});
	}

	appWindow.listen('FileDropUi::Progress', ({ payload }) => {
		const [file_index, incr] = payload;
		files.update(files => {
			const file = indexed_paths[file_index];

			if (!file.error) {
				file.bytes_received += incr;

				total_received.update(total_received => {
					return total_received + incr;
				});

				if (file.bytes_received >= file.total_size) {
					add_log(file.path, true);
				}
			}

			// TODO sort
			return files;
		});
	});

	appWindow.listen('FileDropUi::Error', ({ payload }) => {
		const [file_index, error] = payload;
		files.update(files => {
			const file = indexed_paths[file_index];
			if (!file.error) error_count += 1;
			file.error = error;

			total_received.update(total_received => {
				return total_received + (file.total_size - file.bytes_received);
			});

			add_log(file.path, false);

			// TODO sort
			return files;
		});
	});

	let complete = false;
	appWindow.listen('FileDropUi::Complete', () => {
		complete = true;
	});

	const logs_gc = setInterval(() => {
		logs.update(logs => {
			const date = new Date();
			return logs.filter(logs => {
				return date - logs.gc < 2000;
			});
		});
	}, 2000);
	onDestroy(() => clearInterval(logs_gc));

	appWindow.emit('FileDropUi::Ready');

	window.file_drop_files = files;

	let speed = null;
	$: {
		if (!complete && $total_received <= total_size) {
			speed = humanFileSize($total_received / Math.max(new Date().getTime() / 1000 - started, 1), true, 2);
		}

		if ($total_received >= total_size) {
			complete = true;
		}
	}
</script>

<main class:complete>
	<div id="progress-container">
		<svg id="download-icon" enable-background="new 0 0 24 24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23.61"
			><path
				d="m12 14c-.192 0-.384-.073-.53-.22l-5.25-5.25c-.471-.471-.136-1.28.53-1.28h2.75v-6c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v6h2.75c.666 0 1.001.809.53 1.28l-5.25 5.25c-.146.147-.338.22-.53.22z" /><path
				d="m11 21.192-11-5.042v2.35c0 .199.118.379.301.458l10.699 4.652z" /><path
				d="m13 21.192v2.419l10.699-4.652c.183-.08.301-.26.301-.459v-2.35z" /><path
				d="m17.581 11.558-3.636 3.636c-.52.52-1.211.806-1.945.806s-1.425-.286-1.944-.806l-3.636-3.636-6.42 2.942 12 5.5 12-5.5z" /></svg>
		<svg
			id="progress-radial"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 100 100"
			style:stroke-dasharray={Math.PI * 2 * 50}
			style:stroke-dashoffset={Math.PI * 2 * 50 * (1 - Math.min($total_received / total_size, 1))}>
			<circle cx="50" cy="50" r="50" stroke-width="6" fill="none" />
		</svg>
		<div id="stats">
			<div id="stats-received">
				{humanFileSize($total_received, true, 2)} / {humanFileSize(total_size, true, 2)}
			</div>
			{#if speed}
				<div id="stats-speed">{speed}/s</div>
			{/if}
		</div>

		<div id="notifications">
			{#if complete}
				<div class="notification completed" class:success={error_count === 0}>
					{#if error_count === 0}
						🥳 Completed with no errors
					{:else if error_count === 1}
						⚠️ Completed with 1 error (see below)
					{:else}
						⚠️ Completed with {error_count} errors (see below)
					{/if}
				</div>
			{/if}
			{#each $logs as log, i (log.id)}
				<div class="notification" class:success={log.success} style:transform="translateY({2.6 * (complete ? 1 + i : i)}em)">
					{#if log.success}
						📨
					{:else}
						⚠️
					{/if}
					&nbsp;{log.text}
				</div>
			{/each}
		</div>
	</div>

	<div id="logs">
		<div id="logs-table">
			<div style="grid-row:1" class="th">Path</div>
			<div style="grid-row:1" class="th">Size</div>
			<div style="grid-row:1" class="th">Progress</div>
			{#each $files as file, i (file.id)}
				<div style="grid-row:{i + 2}" class="col-path">
					<span title={file.path}>{file.path}</span>
				</div>
				<div style="grid-row:{i + 2}" class="col-size">
					{#if file.total_size != null}
						{humanFileSize(file.total_size, true, 2)}
					{/if}
				</div>
				<div style="grid-row:{i + 2}" class="col-progress">
					{#if file.error != null}
						<!-- FIXME needs to be opaque tooltip -->
						<div class="error" use:tippy={file.error}>ERROR</div>
					{:else if file.total_size != null}
						<div class="progress">
							{#if file.bytes_received >= file.total_size}
								<div>COMPLETE</div>
								<div class="bar" style="transform:scaleX(1)" />
							{:else}
								<div>
									{Math.round((((file.bytes_received ?? 0) / file.total_size) * 100 + Number.EPSILON) * 100) / 100}%
								</div>
								<div class="bar" style="transform:scaleX({(file.bytes_received ?? 0) / file.total_size})" />
							{/if}
						</div>
					{/if}
				</div>
			{/each}
		</div>
	</div>
</main>

<style>
	main {
		width: 100%;
	}

	#progress-radial circle {
		transition: stroke 0.5s;
	}
	#download-icon {
		transition: fill 0.5s;
	}
	#stats {
		transition: color 0.5s;
	}
	main:not(.complete) #progress-radial circle {
		stroke: #0075c9;
	}
	main:not(.complete) #download-icon {
		fill: #0075c9;
	}
	main:not(.complete) #stats {
		color: #219cf5;
	}
	main.complete #progress-radial circle {
		stroke: #00c984;
	}
	main.complete #download-icon {
		fill: #00c984;
	}
	main.complete #stats {
		color: #00c984;
	}

	#progress-container {
		position: relative;
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		z-index: -1;
	}
	#download-icon {
		object-fit: contain;
		max-height: calc(60% - 10em);
		max-width: calc(60% - 10em);
		top: 50%;
		left: 50%;
		margin-bottom: 2em;
	}

	#progress-radial {
		overflow: visible;
		height: calc(94% - 10em);
		width: calc(94% - 10em);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
	}

	#stats {
		text-align: center;
		line-height: 1.6;
	}

	#notifications {
		position: absolute;
		text-align: center;
		width: calc(100% - 2.6em - 2.6em);
		bottom: 2.6em;
		left: 2.6em;
		height: 0;
		z-index: 1;
	}
	#notifications .notification {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		line-height: 1;
		overflow-y: hidden;
	}
	#notifications .notification:not(.completed) {
		transition: transform 0.25s;
		animation-name: fade-in-out;
		animation-duration: 3.25s;
		animation-fill-mode: forwards;
	}
	#notifications .notification.completed {
		animation-name: fade-in;
		animation-duration: 0.25s;
		animation-fill-mode: forwards;
	}
	#notifications .notification.success {
		color: #00c984;
	}
	@keyframes fade-in-out {
		0% {
			opacity: 0;
		}
		/* 0.25s */
		7.69230769% {
			opacity: 1;
		}
		/* 2.25s */
		69.2307692% {
			opacity: 1;
		}
		/* 3.25s */
		100% {
			opacity: 0;
		}
	}
	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	#logs {
		width: 100%;
		height: 100vh;
		background-color: #1a1a21;
		box-shadow: inset 0 0 4px 0px rgb(0 0 0 / 20%);
		border: 1px solid #101010;
		padding: 1em;
	}
	#logs-table {
		display: grid;
		grid-template-columns: 1fr auto 25%;
		align-items: center;
		row-gap: 1rem;
		column-gap: 1rem;
		padding: 1rem;
		width: 100%;
		border-radius: 0.3rem;
		background-color: #121217;
	}
	#logs .th {
		font-weight: bold;
		text-align: center;
	}
	#logs .col-path {
		max-width: 100%;
		direction: rtl;
		text-align: left;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	#logs .progress {
		position: relative;
		z-index: 1;
		overflow: hidden;
		background-color: #0e0e12;
		border: 1px solid #0b0b0e;
	}
	#logs .progress .bar {
		transform-origin: left center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: transform 0.25s;
		background-color: #1a986d;
		z-index: -1;
		border-radius: 0.3rem;
	}
	#logs .error {
		background-color: #c91a1a;
		cursor: help;
	}
	#logs .col-progress > div {
		padding: 0.3rem;
		border-radius: 0.3rem;
		font-size: 0.9em;
		text-align: center;
		text-shadow: none;
		cursor: default;
	}
</style>
