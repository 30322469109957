<script>
	import { invoke } from '@tauri-apps/api/tauri';
	import { onDestroy } from 'svelte';
	import { SETTINGS } from './settings';

	export let setting;

	let value = setting.key in $SETTINGS ? $SETTINGS[setting.key] : setting.default;

	function setValue(new_value) {
		value = new_value;
		invoke('update_setting', { key: setting.key, value: JSON.stringify(new_value) });
	}

	onDestroy(
		SETTINGS.subscribe(settings => {
			if (setting.key in settings) {
				value = settings[setting.key];
			} else {
				value = setting.default;
			}
		})
	);
</script>

<div class="setting">
	<div class="title">{setting.name}</div>
	<div class="description">{setting.description}</div>
	<div class="input">
		{#if setting.slider && setting.range != null}
			<label>
				<input type="range" min={setting.range[0]} max={setting.range[1]} {value} />
				<input type="number" min={setting.range[0]} max={setting.range[1]} {value} />
			</label>
		{:else if setting.type === 'bool'}
			<div class="switch" class:on={!!value} on:click={() => setValue(!value)}>
				<div class="nub" />
			</div>
		{/if}
	</div>
</div>

<style>
	.setting {
		display: flex;
		flex-direction: column;
		font-size: 0.9em;
	}
	.setting:not(:last-child) {
		margin-bottom: 2rem;
	}
	.setting > .title {
		font-size: 1.2em;
	}
	.setting > .description {
		color: #9f9f9f;
		margin-top: 0.5rem;
	}
	.setting > .input {
		margin-top: 0.75rem;
	}
	label > * {
		vertical-align: middle;
	}

	.switch {
		cursor: pointer;
		border-radius: 3em;
		width: 4.4em;
		padding: 0.24em;
		background-color: #1c1c24;
		border: 1px solid rgba(0, 0, 0, 0.55);
		transition: background-color 0.25s;
		margin-left: -0.2rem;
	}
	.switch .nub {
		background-color: #fff;
		border-radius: 50%;
		width: 1.2em;
		height: 1.2em;
		transform: translateX(0);
		transition: transform 0.25s;
	}
	.switch.on {
		background-color: rgb(0, 106, 255);
	}
	.switch.on .nub {
		transform: translateX(calc(4.4em - 0.48em - 100% - 0.24em));
	}
</style>
