<script>
	import { OS_ICONS } from '../consts';
	import { DEVICE_CONNECTED, DEVICE_CONNECTING, DEVICE_ONLINE } from '../friends';

	export let device;
	export let activeDevice;
	export let status;
	export let src;
</script>

<div class="device-list-item" class:active={$activeDevice?.device === device} on:click={() => activeDevice.set({ device, src })}>
	{#if status === DEVICE_CONNECTED}
		<img class="connected" src="img/connected.svg" title="Connected" alt="Connected" />
	{:else if status === DEVICE_CONNECTING}
		<img class="connected spin" src="img/loading-spin.svg" title="Connecting" alt="Connecting" />
	{:else}
		{@const title = status === DEVICE_ONLINE ? 'Online' : 'Offline'}
		<span class="status" class:online={status === DEVICE_ONLINE} {title} alt={title} />
	{/if}

	{#if device.os !== 'Other'}
		<img class="os" src={OS_ICONS[device.os]} alt={device.os} />
	{/if}

	<span>{device.hostname}</span>
</div>

<style>
	.device-list-item {
		font-size: 0.85em;
		padding: 0.5rem;
		padding-left: 0.7rem;
		padding-right: 0.7rem;
		border-radius: 0.4rem;
		background-color: #2e2e39;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
		border: 1px solid #181818;
	}
	.device-list-item.active {
		background-color: #005cff;
	}
	.device-list-item:not(:last-child) {
		margin-bottom: 1rem;
	}
	.device-list-item .os {
		height: 1em;
	}
	.device-list-item .status,
	.device-list-item .connected {
		height: 0.9em;
		width: 0.9em;
		display: inline-block;
	}
	.device-list-item .status {
		border-radius: 50%;
	}
	.device-list-item .status.online {
		background-color: #00c400;
	}
	.device-list-item .status:not(.online) {
		background-color: #949393;
	}
	.device-list-item > * {
		vertical-align: middle;
	}
	.device-list-item > *:not(:last-child) {
		margin-right: 0.15rem;
	}
</style>
