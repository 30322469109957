<svg
	version="1.1"
	id="Capa_1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 409.6 409.6"
	style="enable-background:new 0 0 409.6 409.6;"
	xml:space="preserve">
	<g>
		<g>
			<path
				d="M170.665,0h-153.6C7.685,0,0,7.685,0,17.065V204.8c0,9.385,7.685,17.065,17.065,17.065h153.6
			c9.385,0,17.07-7.68,17.07-17.065V17.065C187.735,7.685,180.05,0,170.665,0z" />
		</g>
	</g>
	<g>
		<g>
			<path
				d="M392.535,187.735h-153.6c-9.385,0-17.07,7.68-17.07,17.065v187.735c0,9.38,7.685,17.065,17.07,17.065h153.6
			c9.38,0,17.065-7.685,17.065-17.065V204.8C409.6,195.415,401.915,187.735,392.535,187.735z" />
		</g>
	</g>
	<g>
		<g>
			<path
				d="M170.665,256h-153.6C7.685,256,0,263.685,0,273.065v119.47c0,9.38,7.685,17.065,17.065,17.065h153.6
			c9.385,0,17.07-7.685,17.07-17.065v-119.47C187.735,263.685,180.05,256,170.665,256z" />
		</g>
	</g>
	<g>
		<g>
			<path
				d="M392.535,0h-153.6c-9.385,0-17.07,7.685-17.07,17.065v119.47c0,9.38,7.685,17.065,17.07,17.065h153.6
			c9.38,0,17.065-7.685,17.065-17.065V17.065C409.6,7.685,401.915,0,392.535,0z" />
		</g>
	</g>
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
	<g />
</svg>
