// Fit SVG viewBox
// { const bbox = document.querySelector('svg').getBBox(); document.querySelector('svg').setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`); }

import Hotkeys from './Hotkeys.svelte';
import Logs from './Logs.svelte';
import License from './License.svelte';
import About from './About.svelte';

export default {
	interface: {
		name: 'Interface',
		icon: 'img/layout.svg',
	},

	keyboard: {
		name: 'Keyboard',
		icon: 'img/keyboard.svg',
	},

	hotkeys: {
		name: 'Hotkeys',
		icon: 'img/hotkeys.svg',
		component: Hotkeys,
	},

	system: {
		name: 'System',
		icon: {
			src: 'img/monitor.svg',
			black: true,
		},
	},

	network: {
		name: 'Network',
		icon: 'img/network.svg',
	},

	clipboard: {
		name: 'Clipboard',
		icon: 'img/clipboard.svg',
	},

	file_sharing: {
		name: 'File Sharing',
		icon: 'img/file_sharing.svg',
	},

	screen_edges: {
		name: 'Screen Edges',
		icon: 'img/screen_edges.svg',
	},

	logs: {
		name: 'Logs',
		icon: 'img/logs.svg',
		component: Logs,
	},

	license: {
		name: 'License',
		icon: 'img/license.svg',
		component: License,
	},

	about: {
		name: 'About',
		icon: 'img/about.svg',
		component: About,
	},
};
