<script>
	import { writable, get } from 'svelte/store';
	import { appWindow } from '@tauri-apps/api/window';
	import { invoke } from '@tauri-apps/api/tauri';
	import { afterUpdate, onDestroy, onMount } from 'svelte';
	import { OS_ICONS } from './consts';
	import Save from './icons/Save.svelte';
	import Reset from './icons/Layout.svelte';
	import ToolbarButton from './ToolbarButton.svelte';
	import { default as MonitorIcon } from './icons/Monitor.svelte';

	const WORKSPACE = writable({ monitors: [], device_group: [] });

	window.__WORKSPACE__ = WORKSPACE;

	// TODO need to add a way to move all of a device's monitors at once

	let dragging_monitor;
	let overlaps = false;
	let monitor_depths = {};
	let monitor_depth = 0;
	let scale_factor = 0.1;
	let pan_offset = [0, 0];
	let monitor_index = {};
	let bbox = {
		left: Number.MAX_VALUE,
		top: Number.MAX_VALUE,
		right: Number.MIN_VALUE,
		bottom: Number.MIN_VALUE,
	};

	function update_monitor_styles() {
		const monitors = get(WORKSPACE).monitors;
		document.querySelectorAll('#workspace .monitor-sized').forEach(monitor => {
			monitor.setAttribute('style', monitors[parseInt(monitor.dataset.index)].style(monitor.classList.contains('monitor-3d')));
		});
	}

	function update_center_offset(monitors) {
		const workspace = document.getElementById('workspace');
		if (!workspace) return;

		pan_offset = [0, 0];

		bbox = {
			left: Number.MAX_VALUE,
			top: Number.MAX_VALUE,
			right: Number.MIN_VALUE,
			bottom: Number.MIN_VALUE,
		};

		monitors.forEach(monitor => {
			pan_offset[0] = Math.min(pan_offset[0], monitor.user_rect.left);
			pan_offset[1] = Math.min(pan_offset[1], monitor.user_rect.top);

			bbox.left = Math.min(bbox.left, monitor.user_rect.left);
			bbox.top = Math.min(bbox.top, monitor.user_rect.top);
			bbox.right = Math.max(bbox.right, monitor.user_rect.right);
			bbox.bottom = Math.max(bbox.bottom, monitor.user_rect.bottom);
		});

		pan_offset = [-pan_offset[0], -pan_offset[1]];

		const width = bbox.right - bbox.left;
		const height = bbox.bottom - bbox.top;

		const scale_factor_w = (Math.max(Math.min(workspace.clientWidth, 1100), 500) * 0.85) / width;
		const scale_factor_h = (Math.max(Math.min(workspace.clientHeight, 800), 300) * 0.85) / height;
		scale_factor = Math.min(scale_factor_w, scale_factor_h);

		workspace.firstChild.style.width = `${width * scale_factor}px`;
		workspace.firstChild.style.height = `${height * scale_factor}px`;
	}
	window.addEventListener('resize', () => {
		update_center_offset(get(WORKSPACE).monitors);
		update_monitor_styles();
	});

	// TODO instead of panning we should just normalize (0, 0)
	function pan_and_scale_x(x) {
		return (x + pan_offset[0]) * scale_factor;
	}
	function pan_and_scale_y(y) {
		return (y + pan_offset[1]) * scale_factor;
	}
	function scale(w) {
		return w * scale_factor;
	}

	function wallpaper_to_promise(wallpaper, all_wallpapers) {
		if (wallpaper === 'Pending') {
			return new Promise(() => {});
		} else if (wallpaper === 'Err') {
			return Promise.reject();
		} else {
			const linkedId = wallpaper['Linked'];
			if (linkedId != null) {
				let linked = null;
				if (all_wallpapers != null) {
					linked = wallpaper_to_promise(all_wallpapers[linkedId], all_wallpapers);
				} else {
					linked = get(WORKSPACE).monitors.find(monitor => monitor.wallpaper_id === linkedId)?.wallpaper;
				}
				return linked ?? Promise.reject();
			} else {
				return Promise.resolve(wallpaper['Ok']);
			}
		}
	}

	class Monitor {
		constructor(i, workspace, monitor) {
			this.index = i;
			this.id = monitor.id;
			this.hardware_id = monitor.hardware_id;
			this.user_rect = monitor.user_rect;
			this.os_rect = monitor.os_rect;
			this.macos_resolution = monitor.macos_resolution;
			this.wallpaper_id = monitor.wallpaper_id;
			this.wallpaper =
				monitor.wallpaper_id != null && monitor.wallpaper_id in workspace.wallpapers
					? wallpaper_to_promise(workspace.wallpapers[monitor.wallpaper_id], workspace.wallpapers)
					: Promise.reject();
			this.device = workspace.devices[monitor.device_index];
			this.is_local = monitor.device_index === 0;
		}

		style(is_3d) {
			let style = `width: ${scale(this.os_rect.right - this.os_rect.left)}px; height: ${scale(this.os_rect.bottom - this.os_rect.top)}px;`;

			const dragging = dragging_monitor && dragging_monitor.instance === this;

			// Dragging unaware style (animated)
			{
				const user_rect = dragging ? this.user_rect_drag_start : this.user_rect;
				if (monitor_depths != null) {
					const depth = monitor_depths[this.id] || 0;
					style += `transform: translate3d(${pan_and_scale_x(user_rect.left)}px, ${pan_and_scale_y(user_rect.top)}px, ${depth * 2}em);`;
					if (is_3d) {
						style += `z-index: ${depth * 2 - 1};`;
					} else {
						style += `z-index: ${depth * 2};`;
					}
				} else {
					style += `transform: translate(${pan_and_scale_x(user_rect.left)}px, ${pan_and_scale_y(user_rect.top)}px);`;
				}
			}

			// Dragging aware style (not animated)
			if (dragging) {
				style += `left: ${scale(this.user_rect.left - this.user_rect_drag_start.left)}px; top: ${scale(
					this.user_rect.top - this.user_rect_drag_start.top
				)}px;`;
			}

			return style;
		}
	}

	function do_rects_overlap(a, b) {
		return a.left <= b.right && a.right >= b.left && a.top <= b.bottom && a.bottom >= b.top;
	}

	function calculate_device_depth(monitors) {
		// Build a "stack" of monitors

		overlaps = false;
		monitor_depths = {};
		monitor_depth = 0;

		let found_overlap = true;
		while (found_overlap) {
			found_overlap = false;

			for (let i = 0; i < monitors.length - 1; i++) {
				for (let j = i + 1; j < monitors.length; j++) {
					const monitor = monitors[i];
					const other = monitors[j];
					if (monitor_depths[monitor.id] === monitor_depths[other.id] && do_rects_overlap(monitor.user_rect, other.user_rect)) {
						const monitor_size = (monitor.user_rect.right - monitor.user_rect.left) * (monitor.user_rect.bottom - monitor.user_rect.top);
						const other_size = (other.user_rect.right - other.user_rect.left) * (other.user_rect.bottom - other.user_rect.top);
						const promote = monitor_size < other_size ? monitor : other;

						monitor_depths[promote.id] = monitor_depths[promote.id] || 0;
						monitor_depth = Math.max(monitor_depth, ++monitor_depths[promote.id]);
						overlaps = true;
						found_overlap = true;
					}
				}
			}
		}

		if (!overlaps) {
			monitor_depths = null;
		}
	}

	let workspaceUpdate = false;
	appWindow.listen('WorkspaceUpdate', ({ payload }) => {
		workspaceUpdate = true;

		const monitors = payload.monitors.map((monitor, i) => {
			monitor = new Monitor(i, payload, monitor);
			monitor_index[monitor.id] = monitor;
			return monitor;
		});

		WORKSPACE.set({
			device_group: payload.device_group,
			monitors,
		});
	});
	appWindow.listen('WallpaperUpdate', ({ payload: { wallpaper_id, result } }) => {
		WORKSPACE.update(workspace => {
			workspace.monitors.forEach(monitor => {
				if (monitor.wallpaper_id === wallpaper_id) {
					monitor.wallpaper = wallpaper_to_promise(result);
				}
			});
			return workspace;
		});
	});
	WORKSPACE.subscribe(workspace => {
		update_center_offset(workspace.monitors);
		calculate_device_depth(workspace.monitors);
		return workspace;
	});

	function drag_monitor_move(e) {
		if (!dragging_monitor) {
			window.removeEventListener('mousemove', drag_monitor_move);
			return;
		}

		const delta = [
			Math.round((e.clientX - dragging_monitor.start[0]) / scale_factor),
			Math.round((e.clientY - dragging_monitor.start[1]) / scale_factor),
		];

		const user_rect_drag_start = dragging_monitor.instance.user_rect_drag_start;
		dragging_monitor.instance.user_rect.left = user_rect_drag_start.left + delta[0];
		dragging_monitor.instance.user_rect.top = user_rect_drag_start.top + delta[1];
		dragging_monitor.instance.user_rect.right = user_rect_drag_start.right + delta[0];
		dragging_monitor.instance.user_rect.bottom = user_rect_drag_start.bottom + delta[1];

		const monitors = get(WORKSPACE).monitors;

		// Snap to other monitors
		{
			const SNAP_DISTANCE = 50;

			const EDGE_LEFT = 0;
			const EDGE_RIGHT = 1;
			const EDGE_TOP = 2;
			const EDGE_BOTTOM = 3;

			const EDGE_LEFTRIGHT = 4;
			const EDGE_RIGHTLEFT = 5;

			const EDGE_TOPBOTTOM = 6;
			const EDGE_BOTTOMTOP = 7;

			const EDGE_CENTER_HORIZONTAL = 8;
			const EDGE_CENTER_VERTICAL = 9;

			const rect = dragging_monitor.instance.user_rect;
			const width = rect.right - rect.left;
			const height = rect.bottom - rect.top;
			const closest = [null, null, null, null, null, null, null, null, null, null];
			for (let i = 0; i < monitors.length; i++) {
				const monitor = monitors[i];
				if (monitor === dragging_monitor.instance) continue;

				const snap = monitor.user_rect;
				const distances = [
					Math.abs(snap.left - rect.left),
					Math.abs(snap.right - rect.right),
					Math.abs(snap.top - rect.top),
					Math.abs(snap.bottom - rect.bottom),

					Math.abs(snap.left - rect.right),
					Math.abs(snap.right - rect.left),

					Math.abs(snap.top - rect.bottom),
					Math.abs(snap.bottom - rect.top),

					Math.abs(snap.left + (snap.right - snap.left) / 2 - rect.left - width / 2),
					Math.abs(snap.top + (snap.bottom - snap.top) / 2 - rect.top - height / 2),
				];
				distances.forEach((distance, edge) => {
					const closest_edge = closest[edge];
					if (closest_edge === null) {
						closest[edge] = { distance, monitor, edge };
					} else if (closest_edge.distance >= distance) {
						closest_edge.distance = distance;
						closest_edge.monitor = monitor;
					}
				});
			}

			let closest_horiz_vert_edge = [null, null];
			for (let edge = EDGE_LEFT; edge <= EDGE_CENTER_VERTICAL; edge++) {
				const closest_edge = closest[edge];

				if (!closest_edge) continue;
				if (closest_edge.distance > SNAP_DISTANCE) continue;

				let select;
				switch (edge) {
					case EDGE_LEFT:
					case EDGE_RIGHT:
					case EDGE_LEFTRIGHT:
					case EDGE_RIGHTLEFT:
					case EDGE_CENTER_HORIZONTAL:
						select = 0;
						break;

					default:
						select = 1;
				}

				if (closest_horiz_vert_edge[select] === null || closest_edge.distance < closest_horiz_vert_edge[select].distance) {
					closest_horiz_vert_edge[select] = closest_edge;
				}
			}
			const [closest_horiz_edge, closest_vert_edge] = closest_horiz_vert_edge;

			if (closest_horiz_edge) {
				const snap = closest_horiz_edge.monitor.user_rect;
				switch (closest_horiz_edge.edge) {
					case EDGE_LEFT:
						rect.left = snap.left + 1;
						rect.right = rect.left + width;
						break;

					case EDGE_RIGHT:
						rect.right = snap.right + 1;
						rect.left = rect.right - width;
						break;

					case EDGE_LEFTRIGHT:
						rect.right = snap.left - 1;
						rect.left = rect.right - width;
						break;

					case EDGE_RIGHTLEFT:
						rect.left = snap.right + 1;
						rect.right = rect.left + width;
						break;

					case EDGE_CENTER_HORIZONTAL:
						rect.left = snap.left + (snap.right - snap.left) / 2 - width / 2 + 1;
						rect.right = rect.left + width;
						break;
				}
			}

			if (closest_vert_edge) {
				const snap = closest_vert_edge.monitor.user_rect;
				switch (closest_vert_edge.edge) {
					case EDGE_TOP:
						rect.top = snap.top + 1;
						rect.bottom = rect.top + height;
						break;

					case EDGE_BOTTOM:
						rect.bottom = snap.bottom + 1;
						rect.top = rect.bottom - height;
						break;

					case EDGE_TOPBOTTOM:
						rect.bottom = snap.top - 1;
						rect.top = rect.bottom - height;
						break;

					case EDGE_BOTTOMTOP:
						rect.top = snap.bottom + 1;
						rect.bottom = rect.top + height;
						break;

					case EDGE_CENTER_VERTICAL:
						rect.top = snap.top + (snap.bottom - snap.top) / 2 - height / 2 + 1;
						rect.bottom = rect.top + height;
						break;
				}
			}
		}

		calculate_device_depth(monitors);
		update_monitor_styles();
	}
	function drag_monitor_end() {
		const dragging_monitor_element = dragging_monitor.element;
		dragging_monitor = null;

		window.removeEventListener('mousemove', drag_monitor_move);
		window.removeEventListener('mouseup', drag_monitor_end);

		const monitors = get(WORKSPACE).monitors;

		// Prevent animation from being applied to the monitor that was just dragged
		dragging_monitor_element.classList.add('dragging-end');
		dragging_monitor_element.previousElementSibling.classList.add('dragging-end');
		dragging_monitor_element.nextElementSibling.classList.add('dragging-end');

		update_monitor_styles();

		// We need to trigger a reflow to make sure the animation is applied
		dragging_monitor_element.offsetWidth;
		dragging_monitor_element.previousElementSibling.offsetWidth;
		dragging_monitor_element.nextElementSibling.offsetWidth;

		dragging_monitor_element.classList.remove('dragging-end');
		dragging_monitor_element.previousElementSibling.classList.remove('dragging-end');
		dragging_monitor_element.nextElementSibling.classList.remove('dragging-end');

		calculate_device_depth(monitors);
		update_center_offset(monitors);
		update_monitor_styles();
	}
	function drag_monitor_start(e) {
		e.preventDefault();

		const monitor_instance = get(WORKSPACE).monitors[parseInt(e.target.dataset.index)];
		if (!monitor_instance) throw new Error('Invalid monitor index');

		monitor_instance.user_rect_drag_start = {
			...monitor_instance.user_rect,
		};

		dragging_monitor = {
			element: e.target,
			instance: monitor_instance,
			start: [e.clientX, e.clientY],
		};

		window.addEventListener('mousemove', drag_monitor_move);
		window.addEventListener('mouseup', drag_monitor_end);
	}

	function identify() {
		invoke('identify_monitors');
	}
	function save() {
		const serialized = [];
		const workspace = get(WORKSPACE);
		workspace.monitors.forEach(monitor => {
			serialized.push([
				monitor.device.id,
				monitor.hardware_id,
				[monitor.user_rect.left - monitor.os_rect.left, monitor.user_rect.top - monitor.os_rect.top],
			]);
		});
		invoke('update_layout', {
			deviceGroup: workspace.device_group,
			offsets: serialized,
		});
	}
	function reset() {
		const monitors = get(WORKSPACE).monitors;

		monitors.forEach(monitor => {
			monitor.user_rect = { ...monitor.os_rect };
		});

		calculate_device_depth(monitors);
		update_center_offset(monitors);
		update_monitor_styles();
	}

	onMount(() => {
		const monitors = get(WORKSPACE).monitors;
		calculate_device_depth(monitors);
		update_center_offset(monitors);
		update_monitor_styles();
	});
	onDestroy(drag_monitor_end);

	afterUpdate(() => {
		if (!workspaceUpdate) return;
		workspaceUpdate = false;
		update_monitor_styles();
	});

	let ctrl = false;
	let shift = false;
	function keydown(e) {
		if (e.key === 'Control') ctrl = true;
		else if (e.key === 'Shift') shift = true;
	}
	function keyup(e) {
		if (e.key === 'Control') ctrl = false;
		else if (e.key === 'Shift') shift = false;
	}
	window.addEventListener('keydown', keydown);
	window.addEventListener('keyup', keyup);
	onDestroy(() => {
		window.removeEventListener('keydown', keydown);
		window.removeEventListener('keyup', keyup);
	});
	/* TODO Implement that shi */

	function renderMonitorNumber(elem) {
		const size = Math.max(elem.clientHeight, elem.clientWidth);
		const css = size + 'px';
		elem.style.fontSize = size * 0.65 + 'px';
		elem.style.width = css;
		elem.style.height = css;
	}

	let identify_unlisten;
	let identify_timeout;
	let identify_active = false;
	appWindow
		.listen('IdentifyMonitors', () => {
			if (identify_timeout != null) clearTimeout(identify_timeout);
			identify_active = true;
			identify_timeout = setTimeout(() => (identify_active = false), 10000);
		})
		.then(unlisten => {
			identify_unlisten = unlisten;
		});
	onDestroy(() => {
		if (identify_unlisten != null) identify_unlisten();
		if (identify_timeout != null) clearTimeout(identify_timeout);
	});
</script>

<!-- TODO we should be able to identify even if we dont have >1 monitor really -->
<div id="workspace" class:editable={process.env.NODE_ENV === 'development' || $WORKSPACE.device_group.length > 1}>
	<div class:depth={monitor_depths != null}>
		{#each $WORKSPACE.monitors as monitor, i (monitor.id)}
			<div class="monitor-3d monitor-sized" data-index={i} style={monitor.style(true)}>
				<div class="left" />
				<div class="top" />
			</div>
			<div
				class="monitor monitor-sized"
				data-index={i}
				style={monitor.style()}
				on:dragstart={drag_monitor_start}
				draggable={$WORKSPACE.device_group.length > 1 ? 'true' : 'false'}>
				{#if monitor.wallpaper != null}
					{#await monitor.wallpaper}
						<div class="wallpaper default loading" />
					{:then wallpaper}
						<div class="wallpaper">
							<div style="background-image:url('{wallpaper.data}')">
								<div />
							</div>
						</div>
					{:catch}
						<div class="wallpaper default" />
					{/await}
				{:else}
					<div class="wallpaper default" />
				{/if}
				<div class="info">
					{#if monitor.device.os !== 'Other'}
						<img draggable="false" src={OS_ICONS[monitor.device.os]} alt={monitor.device.os} class="os" /><br />
					{/if}
					{monitor.device.ip}<br />
					{monitor.device.user}@{monitor.device.hostname}<br />

					{#if monitor.macos_resolution != null}
						{monitor.macos_resolution[0]}x{monitor.macos_resolution[1]}
					{:else}
						{monitor.os_rect.right - monitor.os_rect.left + 1}x{monitor.os_rect.bottom - monitor.os_rect.top + 1}
					{/if}
					<br />

					{#if process.env.NODE_ENV === 'development'}
						<span style="font-family:monospace;font-size:9px">{i + 1} / {monitor.id}</span>
					{/if}
				</div>
				<div class="number" class:identify-active={identify_active} use:renderMonitorNumber>
					{i + 1}
				</div>
				<div class="border" />
			</div>
			<div class="monitor-glow monitor-sized" style={monitor.style()} data-index={i} />
		{/each}
	</div>

	<div id="toolbar">
		<div class="buttons">
			<ToolbarButton icon={MonitorIcon} click={identify} tooltip="Which monitor is which?">Identify</ToolbarButton>
			<ToolbarButton
				icon={Save}
				click={save}
				disabled={overlaps}
				tooltip={overlaps ? 'Please ensure no monitors overlap each other before saving.' : null}>Save</ToolbarButton>
			<ToolbarButton icon={Reset} click={reset} tooltip="Resets all monitors to their original, operating system positions, without saving."
				>Reset</ToolbarButton>
		</div>
		<br />
		<div class="keybindings">
			<div class:active={ctrl} class="keybinding">CTRL</div>
			<div class:active={ctrl}>Select individual monitors</div>

			<div class:active={shift} class="keybinding">SHIFT</div>
			<div class:active={shift}>Disable snapping</div>
		</div>
	</div>
</div>

<style>
	#workspace {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		background: radial-gradient(#1a1c22, #09090c);
		z-index: 1;
	}
	#workspace:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: radial-gradient(#00000094 1px, transparent 0);
		background-size: 20px 20px;
		background-position: -14px -14px;
		z-index: -1;
	}

	#workspace > div:first-child {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;

		transform-origin: center center;
		transform-style: preserve-3d;

		transition: transform, width, height;
		transition-duration: 0.25s;
		transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
	}
	#workspace > div:first-child.depth {
		transform: rotateX(-20deg) rotateY(10deg);
	}

	#workspace .monitor {
		z-index: 1;
	}
	#workspace .monitor-3d {
		z-index: 0;
	}

	#workspace .monitor-sized {
		position: absolute;
		top: 0;
		left: 0;
		cursor: default;
		transform-origin: bottom right;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	#workspace.editable .monitor {
		cursor: pointer;
	}
	#workspace.editable .monitor:active {
		box-shadow: 0 0 0 2px #e7a327;
		z-index: 996 !important;
	}
	.monitor-glow {
		pointer-events: none;
	}
	#workspace.editable .monitor:hover:not(:active) + .monitor-glow {
		box-shadow: 0 0 0 2px #06f;
		z-index: 996 !important;
	}
	#workspace .monitor:global(:not(.dragging-end)),
	#workspace .monitor-3d:global(:not(.dragging-end)),
	#workspace .monitor-glow:global(:not(.dragging-end)) {
		transition-property: transform, width, height;
		transition-duration: 0.25s;
		transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
	}
	#workspace .monitor-3d {
		transform-style: preserve-3d;
	}

	#workspace .wallpaper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	#workspace .wallpaper.default {
		background-color: #111111;
	}
	#workspace .wallpaper > div {
		position: absolute;
		top: -2px;
		left: -2px;
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		background-size: cover;
		background-position: center center;
		filter: blur(2px);
	}
	#workspace .wallpaper > div > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.25);
	}
	#workspace .monitor .info {
		text-align: center;
		max-width: 100%;
		max-height: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding: 1rem;
		z-index: 1;
		line-height: 1.6;
		font-size: 0.75em;
	}
	#workspace .monitor .os {
		width: 2em;
		height: 2em;
		margin-bottom: 0.5em;
	}
	#workspace .monitor .number {
		position: absolute;
		top: 0.75rem;
		left: 0.75rem;
		width: 2rem;
		height: 2rem;
		color: #fff;
		background-color: rgba(16, 16, 16, 0.9);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
	}
	#workspace .monitor .number:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
	#workspace .monitor .number:not(.identify-active) {
		opacity: 0;
	}
	#workspace .monitor .border {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border: 2px solid #000;
		z-index: 2;
	}

	#workspace .monitor-3d .left,
	#workspace .monitor-3d .top {
		position: absolute;
		background-color: #000;
	}
	#workspace .monitor-3d .left {
		top: 0em;
		left: 0em;
		width: 2em;
		height: 100%;
		transform: rotateY(90deg);
		transform-origin: center left;
	}
	#workspace .monitor-3d .top {
		top: 0em;
		left: 0em;
		width: 100%;
		height: 2em;
		transform: rotateX(-90deg);
		transform-origin: top center;
	}

	#toolbar {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 2em;
		margin: auto;
		text-align: center;
		z-index: 997;
		pointer-events: none;
	}
	#toolbar > .buttons {
		pointer-events: initial;
		display: inline-grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 0.8em;
	}
	#toolbar > .keybindings {
		margin-top: 1.5em;
		display: inline-grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto;
		grid-gap: 0.75em;
		justify-items: left;
		color: #8f8f9a;
	}
	#toolbar > .keybindings > div {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.8em;
	}
	#toolbar > .keybindings > div:not(.keybinding) {
		grid-column: 2;
	}
	#toolbar > .keybindings .keybinding {
		grid-column: 1;
		border-radius: 4px;
		box-shadow: inset 0 0 0 1px #8f8f9a;
		padding: 0.4em;
		backdrop-filter: blur(5px);
		margin-right: 0.9em;
		width: 100%;
		text-align: center;
		font-size: 0.7em;
	}
	#toolbar > .keybindings .keybinding.active {
		box-shadow: inset 0 0 0 1px #ffb100;
	}
	#toolbar > .keybindings .keybinding.active,
	#toolbar > .keybindings .keybinding.active + div {
		color: #ffb100;
	}

	#workspace:not(.editable) #toolbar {
		display: none;
	}
</style>
