<script>
	import { onMount } from 'svelte';
	import { appWindow } from '@tauri-apps/api/window';
	import ForeignDevice from './ForeignDevice.svelte';

	function accept() {
		appWindow.emit('accept');
	}

	function cancel() {
		appWindow.close();
	}

	const emojis = (() => {
		const hash = window.BEAM_TOFU_EMOJIS[1].match(new RegExp(`.{${Math.floor(window.BEAM_TOFU_EMOJIS[1].length / 4)}}`, 'g'));
		let emojis = [];
		for (let i = 0; i < window.BEAM_TOFU_EMOJIS[0].length / 4; i++) {
			emojis[i] = [window.BEAM_TOFU_EMOJIS[0].slice(4 * i, 4 * (i + 1)), hash[i]];
		}
		return emojis;
	})();

	let prev_rect;
	window.addEventListener('resize', () => {
		if (!prev_rect) return;
		let width = window.outerWidth;
		let height = window.outerHeight;
		if (width === 0 || height === 0) {
			width = window.innerWidth;
			height = window.innerHeight;
		}
		let deltaW = Math.max(Math.ceil(prev_rect.width) - height, 0);
		let deltaH = Math.max(Math.ceil(prev_rect.height) - height, 0);
		if (deltaW > 0 || deltaH > 0) {
			appWindow.emit('render', `${Math.ceil(prev_rect.width) + deltaW},${Math.ceil(prev_rect.height) + deltaH}`);
		}
	});
	function poll_fit_window() {
		const rect = document.body.getBoundingClientRect();
		if (!prev_rect || rect.width !== prev_rect.width || rect.height !== prev_rect.height) {
			prev_rect = rect;
			appWindow.emit('render', `${Math.ceil(rect.width)},${Math.ceil(rect.height)}`);
		}
		requestAnimationFrame(poll_fit_window);
	}
	onMount(poll_fit_window);
</script>

<main>
	<ForeignDevice device={window.BEAM_TOFU_DEVICE} />

	<div class="warning">
		<div class="title">
			<span class="render-emoji">⚠️</span>&nbsp;Ensure that the emojis below match on the other device&nbsp;<span class="render-emoji">⚠️</span>
		</div>
		<div class="content">
			<div>
				If they don't match, an attacker on your network may be trying to take over your device, and you should disconnect immediately. The
				consequences of not thoroughly verifying this fingerprint are dire; an attacker can gain full control of your machine!
			</div>
		</div>
	</div>

	<div>
		{#each emojis as [emojis, hash], i}
			<div class="emojis">
				<div class="hash">{hash}</div>
				<div class="inner">
					{#each emojis as [emoji, name, svg], j}
						<div class="emoji" style="animation-delay: {(i % 2 === 1 ? 3 - j : j) * 0.1 + i * (0.4 + 0.05)}s">
							<img class="symbol render-emoji" src={`/img/emojis/${svg}.svg`} alt={emoji} title={emoji} />
							<!--<div class="symbol render-emoji">{emoji}</div>-->
							<div class="name">{name}</div>
						</div>
					{/each}
				</div>
			</div>
		{/each}
	</div>

	<div id="buttons">
		<button class="accept" on:click={accept}>Accept</button>
		<button class="cancel" on:click={cancel}>Decline</button>
	</div>
	<div id="timer">
		<div style="animation-duration:{Math.max(0, window.BEAM_TOFU_TIMEOUT - new Date().getTime())}ms" />
	</div>
</main>

<style>
	main {
		display: flex;
		flex-direction: column;
		padding: 1em;
	}
	main > *:not(:first-child),
	.emojis:not(:first-child) {
		margin-top: 1em;
	}
	.emojis {
		position: relative;
		padding: 0.6rem;
		background-color: #222228;
		box-shadow: inset 0 0 4px 0px rgb(0 0 0 / 20%);
		border: 1px solid #101010;
		border-radius: 0.4rem;
		cursor: default;
		overflow: hidden;
	}
	.emojis .hash {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.25s, transform 0.25s;
		transform: translateY(100%);
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: monospace;
		font-size: 1.5rem;
	}
	.emojis .inner {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 0.7rem;
		transition: opacity 0.25s, transform 0.25s;
	}
	.emoji {
		text-align: center;
		transition: opacity 0.25s, transform 0.25s;
		overflow: hidden;
	}
	.emojis:hover .inner {
		opacity: 0;
		transform: translateY(-100%);
	}
	.emojis:hover .hash {
		opacity: 1;
		transform: translateY(0%);
	}
	.emoji .symbol {
		font-size: 2em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1;
		width: 1em;
		height: 1em;
		margin: auto;
		display: block;
	}
	.emoji .name {
		margin-top: 0.4rem;
		font-size: 0.5rem;
	}

	button {
		padding: 0;
		margin: 0;
		outline: none;
		border: none;
		background: none;
		color: inherit;
		font: inherit;
		position: relative;
		padding: 0.6em;
		padding-left: 1.2em;
		padding-right: 1.2em;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.9em;
		border-radius: 0.4rem;
		opacity: 1 !important;
		z-index: 1;
		border: 1px solid #101010;
		cursor: pointer;
		flex: 1;
	}
	#buttons {
		display: flex;
	}
	#buttons button:not(:last-child) {
		margin-right: 1em;
	}
	.accept {
		background-color: #009d5c;
	}
	.cancel {
		background-color: #8b0000;
	}

	@keyframes emoji-in-top {
		0% {
			transform: translateY(calc(-100% - 0.7rem));
			opacity: 0;
		}
		100% {
			transform: translateY(0%);
			opacity: 1;
		}
	}
	@keyframes emoji-in-bottom {
		0% {
			transform: translateY(calc(100% + 0.7rem));
			opacity: 0;
		}
		100% {
			transform: translateY(0%);
			opacity: 1;
		}
	}
	.emojis:nth-child(2n + 1) .emoji {
		animation-name: emoji-in-top;
		transform: translateY(calc(-100% - 0.7rem));
	}
	.emojis:nth-child(2n) .emoji {
		animation-name: emoji-in-top;
		transform: translateY(calc(100% + 0.7rem));
	}
	.emoji {
		animation-duration: 0.25s;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
	}

	.render-emoji {
		font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla,
			segoe ui symbol;
	}

	.warning .title {
		padding: 0.5rem;
		background-color: #222228;
		font-size: 0.9em;
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
		text-align: center;
		animation: warning-flash 2s infinite;
	}
	.warning .content {
		padding: 0.7rem;
		display: flex;
		box-shadow: inset 0 0 4px 0px rgb(0 0 0 / 20%);
		background-color: #19191d;
		border-bottom-left-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
		font-size: 0.8em;
		text-align: center;
		line-height: 1.6;
	}
	.warning .content > div {
		max-width: 21rem;
		margin: auto;
	}
	@keyframes warning-flash {
		0% {
			background-color: #222228;
		}
		50% {
			background-color: #4b2e2e;
		}
		100% {
			background-color: #222228;
		}
	}

	#timer {
		position: relative;
		border-radius: 0.4rem;
		height: 0.5rem;
		background-color: #19191d;
		box-shadow: inset 0 0 4px 0px rgb(0 0 0 / 20%);
		border: 1px solid #101010;
	}
	#timer > div {
		position: absolute;
		border-radius: 0.4rem;
		width: 100%;
		height: 100%;
		background-color: #009d5c;
		animation-name: timer;
		animation-fill-mode: forwards;
		animation-timing-function: linear;
	}
	@keyframes timer {
		0% {
			width: 100%;
			background-color: #009d5c;
		}
		100% {
			width: 0%;
			background-color: #8b0000;
		}
	}
</style>
