import { appWindow } from '@tauri-apps/api/window';
import { derived, writable } from 'svelte/store';
import { PEERS } from './discovery';

export const DEVICE_OFFLINE = 0;
export const DEVICE_ONLINE = 1;
export const DEVICE_CONNECTED = 2;
export const DEVICE_CONNECTING = 3;

export const DEVICES_STATUS = writable({});
export const FRIENDS = writable([]);

function statusFromStr(str) {
	switch (str) {
		case 'Established':
			return DEVICE_CONNECTED;

		case 'Pending':
			return DEVICE_CONNECTING;
	}
}

export const FRIENDS_STATUS = derived([FRIENDS, PEERS, DEVICES_STATUS], ([$friends, $peers, $connected]) => {
	// For every friend, check if they're also present in the discovery peers list
	const friends_status = $friends.map(friend => {
		const peer = $peers.find(peer => peer.device.id === friend.id)?.device;
		const device = peer ?? friend;
		return {
			device,
			status: $connected[device.server_addr] ?? (peer != null ? DEVICE_ONLINE : DEVICE_OFFLINE),
		};
	});

	// Sort connected devices first, then online, then offline
	friends_status.sort((a, b) => {
		if (a.status === b.status) return 0;
		if (a.status === DEVICE_CONNECTED) return -1;
		if (b.status === DEVICE_CONNECTED) return 1;
		if (a.status === DEVICE_ONLINE) return -1;
		if (b.status === DEVICE_ONLINE) return 1;
		return 0;
	});

	return friends_status;
});

appWindow.listen('FriendsSync', ({ payload }) => {
	FRIENDS.set(payload);
});

appWindow.listen('DeviceConnectionStatus', ({ payload }) => {
	DEVICES_STATUS.update(status => {
		const newStatus = statusFromStr(payload.status);
		if (newStatus != null) {
			status[payload.server_addr] = newStatus;
		} else {
			delete status[payload.server_addr];
		}
		return status;
	});
});

appWindow.listen('DeviceConnectionStatusSync', ({ payload }) => {
	const statuses = {};
	payload.forEach(({ server_addr, status }) => {
		const newStatus = statusFromStr(status);
		if (newStatus != null) {
			statuses[server_addr] = newStatus;
		}
	});
	DEVICES_STATUS.set(statuses);
});
