import { ready } from './demo/demo';
import { listen, emit } from '@tauri-apps/api/event';

window.addEventListener('error', e => {
	emit('error', `${e.message}${e.stack ? '\n' + e.stack : ''}`);
});

import App from './App.svelte';
import TofuWindow from './TofuWindow.svelte';
import FileDropWindow from './FileDropWindow.svelte';

import { appWindow } from '@tauri-apps/api/window';
import { invoke } from '@tauri-apps/api/tauri';
import { get } from 'svelte/store';
window.__TAURI_EMIT = emit;
window.__TAURI_EMIT_WINDOW = appWindow.emit.bind(appWindow);
window.__TAURI_INVOKE = invoke;
window['$get'] = get;

listen('log', ({ payload }) => console.log(payload));

const app =
	window.location.pathname === 'tofu.html'
		? new TofuWindow({ target: document.body })
		: window.location.pathname === 'file_drop.html'
		? new FileDropWindow({ target: document.body })
		: new App({ target: document.body });

export default app;

ready();