import { default as tippyJs } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';

function updateTippy(node, content) {
	if (node._tippy) node._tippy.destroy();
	if (content) {
		tippyJs(node, {
			content,
			interactive: false,
			animation: 'scale-subtle',
			duration: 200,
		});
	}
}

export function tippy(node, content) {
	updateTippy(node, content, false);
	return {
		update(content) {
			updateTippy(node, content, false);
		},
	};
}

// TODO img draggable=false
