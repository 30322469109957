import { derived, writable } from 'svelte/store';

export const SETTINGS_SCHEMA = writable({});
export const SETTINGS_PROFILES = writable({
	list: [],
	selected: null
});
export const SETTINGS = derived(SETTINGS_PROFILES, $profiles => {
	let found = $profiles.list[0];
	if ($profiles.selected != null) {
		found = $profiles.list.find(profile => profile.id === $profiles.selected);
	}
	if (found != null) {
		return found.inner;
	} else {
		return null;
	}
});