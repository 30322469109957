<script>
	import { invoke } from '@tauri-apps/api/tauri';
	import { DEVICE_CONNECTED, DEVICE_CONNECTING, FRIENDS } from '../../friends';
	import { tippy } from '../../tippy';

	export let status;
	export let device;
</script>

<section class="action-btns">
	<div>
		{#if status === DEVICE_CONNECTED}
			<div
				class="action-btn pill red"
				on:click={() =>
					invoke('disconnect_device', {
						addr: device.server_addr, // TODO
					})}>
				<img src="img/disconnect.svg" alt="" />
				Disconnect
			</div>
		{:else if status === DEVICE_CONNECTING}
			<div
				class="action-btn pill disabled"
				on:click={() =>
					invoke('connect_device', {
						addr: device.server_addr,
					})}>
				<img class="spin" src="img/loading-spin.svg" alt="" />
				Connect
			</div>
		{:else}
			<div
				class="action-btn pill green"
				on:click={() =>
					invoke('connect_device', {
						addr: device.server_addr,
					})}>
				<img src="img/wifi.svg" alt="" />
				Connect
			</div>
		{/if}

		{#if $FRIENDS.some(friend => friend.id === device.id) && /* TODO check src TODO implement forget */ true}
			<div class="action-btn pill red">
				<img src="img/trash.svg" alt="" />
				Forget
			</div>
		{/if}
	</div>
</section>

<nav class="power-buttons">
	<div class="action-btn" class:disabled={device.mac_addr == null} use:tippy={'Wake-On-LAN'}>
		<img src="img/wol.svg" alt="" />
	</div>
	<div class="action-btn" class:disabled={status !== DEVICE_CONNECTED} use:tippy={'Lock'}>
		<img src="img/lock.svg" alt="" />
	</div>
	<div class="action-btn" class:disabled={status !== DEVICE_CONNECTED} use:tippy={'Logout'}>
		<img src="img/logout.svg" alt="" />
	</div>
	<div class="action-btn" class:disabled={status !== DEVICE_CONNECTED} use:tippy={'Sleep'}>
		<img src="img/moon.svg" alt="" />
	</div>
	<div class="action-btn" class:disabled={status !== DEVICE_CONNECTED} use:tippy={'Reboot'}>
		<img src="img/reboot.svg" alt="" />
	</div>
	<div class="action-btn" class:disabled={status !== DEVICE_CONNECTED} use:tippy={'Power Off'}>
		<img src="img/power.svg" alt="" />
	</div>
</nav>

<style>
	.power-buttons {
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;
		padding: 1.1rem;
		padding-bottom: 0;
		justify-content: center;
	}
	.action-btn {
		padding: 1rem;
		border-radius: 50%;
		background-color: #005cff;
		cursor: pointer;
		width: 3rem;
		height: 3rem;
		box-shadow: 0 0 4px 0 rgba(12, 12, 15, 0.3);
	}
	.action-btn.disabled {
		background-color: #3a3a45 !important;
		cursor: default;
	}
	.action-btn img {
		height: 100%;
	}

	.action-btns {
		text-align: center;
	}
	.action-btns > div {
		display: inline-grid;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		align-items: center;
		gap: 1rem;
	}
	.action-btn.pill {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: auto;
		border-radius: 3rem;
		font-size: 1.1em;
		padding: 0.75rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		height: auto;
	}
	.action-btn.pill img {
		margin-right: 0.5em;
		height: 1em;
	}
	.action-btn.pill.disabled {
		pointer-events: none;
	}

	.action-btn.green {
		background-color: #0aa703;
	}
	.action-btn.red {
		background-color: #de1818;
	}
</style>
