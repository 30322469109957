<script>
	import { OS_ICONS } from './consts';
	export let device;
	export let onclick = null;
</script>

<div class="peer" on:click={onclick} style:cursor={onclick ? 'pointer' : 'default'}>
	<div class="name">
		<div class="connection" />
		{device.hostname}
	</div>
	<div class="info">
		<div class="kind">
			{#if device.kind === 'Laptop'}
				<img class="laptop" src="img/laptop.svg" alt="" />
			{:else}
				<img class="other" src="img/monitor.svg" alt="" />
			{/if}
		</div>
		<div class="info-grid">
			<div class="row os" style="grid-row:1">
				{#if device.os !== 'Other'}
					<img src={OS_ICONS[device.os]} alt={device.os} />
					{device.os}
				{/if}
			</div>
			<div class="sub os" style="grid-row:2">OS</div>

			<div class="row user" style="grid-row:1">{device.user}</div>
			<div class="sub user" style="grid-row:2">User</div>

			<div class="row ip" style="grid-row:3;margin-top:0.2em">
				{device.server_addr}
			</div>
			<div class="sub ip" style="grid-row:4">IP Address</div>

			<div class="row beam-version" style="grid-row:3;margin-top:0.2em">
				✓ Beam {device.beam_version}
			</div>
			<div class="sub beam-version" style="grid-row:4">Version</div>
		</div>
	</div>
</div>

<style>
	.peer {
		border: 1px solid #101010;
		font-size: 0.9em;
		display: flex;
		flex-direction: column;
		border-radius: 0.4rem;
	}
	.peer .info {
		flex: 1;
		padding: 0.7rem;
		display: flex;
		box-shadow: inset 0 0 4px 0px rgb(0 0 0 / 20%);
		background-color: #19191d;
		border-bottom-left-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}
	.peer .name {
		padding: 0.5rem;
		background-color: #222228;
		font-size: 0.9em;
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.peer .connection {
		width: 0.8em;
		height: 0.8em;
		border-radius: 50%;
		background-color: #008b0e;
		margin-right: 0.6em;
	}
	.peer .info-grid {
		display: grid;
		grid-template-rows: min-content min-content min-content min-content;
		grid-template-columns: 1fr 1fr;
		row-gap: 0.4em;
		flex: 1;
	}
	.peer .info-grid .os {
		grid-column: 1;
	}
	.peer .info-grid .os.row {
		display: flex;
		align-items: center;
	}
	.peer .info-grid .user {
		grid-column: 2;
	}
	.peer .info-grid .ip {
		grid-column: 1;
	}
	.peer .info-grid .beam-version {
		grid-column: 2;
	}
	.peer .info .sub {
		color: #565656;
		font-size: 0.9em;
	}
	.peer .info .row {
		font-size: 0.9em;
	}
	.peer .os img {
		height: 1em;
		margin-right: 0.5em;
	}
	.peer .kind {
		display: flex;
		width: 25%;
		margin-right: 1rem;
		align-items: center;
		justify-content: center;
	}
	.peer .kind img {
		max-width: 100%;
		max-height: 100%;
		background-image: url(/img/static.gif);
		background-repeat: no-repeat;
	}
	.peer .kind .laptop {
		background-size: 75% 67%;
		background-position: 53% 18%;
	}
	.peer .kind .other {
		background-size: 92% 69%;
		background-position: 53% 12%;
	}
</style>
