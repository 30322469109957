<script>
	import { invoke } from '@tauri-apps/api/tauri';
	import { onMount } from 'svelte';
	import Workspace from './Workspace.svelte';
	import Sidebar, { SIDEBAR_SELECTED, SIDEBAR_WORKSPACE, SIDEBAR_NETWORK, SIDEBAR_SETTINGS, SIDEBAR_HELP } from './Sidebar.svelte';
	import Settings from './settings/Settings.svelte';
	import Network from './network/Network.svelte';

	onMount(() => invoke('ui_sync'));
</script>

<main>
	<Sidebar />
	<div id="content">
		<section class:active={$SIDEBAR_SELECTED == SIDEBAR_WORKSPACE}><Workspace /></section>
		<section class:active={$SIDEBAR_SELECTED == SIDEBAR_NETWORK}><Network /></section>
		<section class:active={$SIDEBAR_SELECTED == SIDEBAR_SETTINGS}><Settings /></section>
		<section class:active={$SIDEBAR_SELECTED == SIDEBAR_HELP} />
	</div>
</main>

<style>
	main {
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;
		display: flex;
	}
	main > #content {
		position: relative;
		flex: 1;
	}
	main > #content > section {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.25s;
		z-index: 1;
	}
	main > #content > section.active {
		opacity: 1;
		pointer-events: all;
		z-index: 2;
	}
</style>
