<script>
	import { onDestroy } from 'svelte';
	import { OS_ICONS } from '../../consts';
	import { PEERS } from '../../discovery';
	import { DEVICES_STATUS, DEVICE_CONNECTED, DEVICE_CONNECTING, DEVICE_OFFLINE, DEVICE_ONLINE, FRIENDS } from '../../friends';
	import ActionButtons from './ActionButtons.svelte';

	export let device;

	let status;
	function updateStatus(devicesStatus, peers) {
		status = devicesStatus[device.server_addr] ?? DEVICE_OFFLINE;

		if (status === DEVICE_OFFLINE && peers.some(peer => peer.device.server_addr === device.server_addr)) {
			// If the device doesn't have a status, it's just in the discovery list
			status = DEVICE_ONLINE;
		}
	}
	updateStatus($DEVICES_STATUS, $PEERS);
	onDestroy(DEVICES_STATUS.subscribe(devicesStatus => updateStatus(devicesStatus, $PEERS)));
	onDestroy(PEERS.subscribe(peers => updateStatus($DEVICES_STATUS, peers)));

	// TODO cleanup markup here
	// TODO add "last seen"
</script>

<main>
	<header>
		<h1>
			<img src={device.kind === 'Laptop' ? 'img/laptop.svg' : 'img/monitor.svg'} alt="" />
			<div>{device.hostname}</div>
		</h1>
		<h2>
			{#if status === DEVICE_CONNECTED}
				<img src="img/connected-w.svg" alt="" />
				Connected
			{:else if status === DEVICE_CONNECTING}
				<img class="spin" src="img/loading-spin.svg" alt="" />
				Connecting...
			{:else if status === DEVICE_ONLINE}
				<img src="img/wifi.svg" alt="" />
				Available
			{:else}
				<img src="img/moon.svg" alt="" />
				Offline
			{/if}
		</h2>
	</header>

	<section class="content scroll-subtle">
		<section class="control-panel">
			<ActionButtons {status} {device} />
		</section>

		<section id="device-details">
			<div class="beam-version">
				<h1>Version</h1>
				<div class="select-all">
					v{device.beam_version}

					{#if device.protocol_incompatible}
						<div class="protocol-compatibility no">Incompatible</div>
					{:else}
						<div class="protocol-compatibility yes">Compatible</div>
					{/if}
				</div>
			</div>

			{#if device.os && device.os !== 'Other'}
				<div class="os">
					<h1>Operating System</h1>
					<div class="select-all">
						<img src={OS_ICONS[device.os]} alt={device.os} />
						{device.os}
					</div>
				</div>
			{/if}

			<div>
				<h1>User</h1>
				<div class="select-all">{device.user}</div>
			</div>

			<div>
				<h1>IP Address</h1>
				<div class="select-all">{device.server_addr}</div>
			</div>

			{#if device.mac_addr}
				<div>
					<h1>MAC Address</h1>
					<div class="select-all">{device.mac_addr}</div>
				</div>
			{/if}

			<div>
				<h1>Device ID</h1>
				<div class="select-all">{device.id}</div>
			</div>
		</section>
	</section>
</main>

<style>
	main {
		max-height: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.control-panel {
		margin-top: 1.5rem;
	}

	header {
		background-color: #005cff;
		border-top-left-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
		overflow: hidden;
		max-width: 100%;
		flex-shrink: 0;
		padding: 1.5rem;
	}
	header h1 {
		margin: 0;
		font-size: 1.5em;
		font-weight: 600;
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
	}
	header h1 > div {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	header h1 > img {
		height: 0.8em;
		margin-right: 0.5rem;
		filter: invert(1);
	}
	header h2 {
		margin: 0;
		font-size: 1em;
		font-weight: normal;
		display: flex;
		align-items: center;
	}
	header h2 > img {
		height: 1em;
		margin-right: 0.35rem;
	}

	.content {
		flex: 1;
		overflow-y: auto;
		background-color: #1f1f29;
		border-bottom-left-radius: 0.4rem;
		border-bottom-right-radius: 0.4rem;
	}

	/*
	#device-details > table {
		border-collapse: collapse;
		word-break: break-all;
	}
	#device-details > table th {
		font-weight: 600;
		text-align: left;
		padding-right: 1rem;
		white-space: nowrap;
	}
	#device-details > table tr:not(:last-child) > * {
		padding-bottom: 0.5rem;
	}
	#device-details > table tr > * {
		vertical-align: top;
	}
	*/

	.os > div {
		display: flex;
		align-items: center;
	}
	.os img {
		height: 1em;
		margin-right: 0.3em;
	}

	.beam-version > div {
		display: flex;
		align-items: center;
	}
	.beam-version .protocol-compatibility {
		font-size: 0.8em;
		font-weight: 600;
		text-transform: uppercase;
		margin-left: 0.5em;
	}
	.beam-version .protocol-compatibility.yes {
		color: #14b10d;
	}
	.beam-version .protocol-compatibility.no {
		color: #de1818;
	}

	#device-details {
		padding: 1.5rem;
	}
	#device-details h1 {
		margin: 0;
		font-size: 1.25em;
		margin-bottom: 0.5rem;
		color: #fff;
	}
	#device-details > div:not(:last-child) {
		margin-bottom: 1.5rem;
	}
	#device-details > div {
		color: #a1a1a1;
	}
</style>
