import { appWindow } from '@tauri-apps/api/window';
import { SETTINGS_PROFILES, SETTINGS_SCHEMA } from './settingsState';
export * from './settingsState';

appWindow.listen('SettingsSync', ({ payload }) => {
	{
		const schema = {};
		JSON.parse(payload.schema).forEach(setting => {
			schema[setting.category_id] = schema[setting.category_id] || [];
			schema[setting.category_id].push(setting);
		});
		SETTINGS_SCHEMA.set(schema);
	}

	SETTINGS_PROFILES.set({
		list: payload.profiles,
		selected: payload.selected_profile
	});
});

appWindow.listen('SettingsProfileUpdated', ({ payload }) => {
	SETTINGS_PROFILES.update($profiles => {
		const index = $profiles.list.findIndex(profile => profile.id === payload.id);
		if (index !== -1) {
			$profiles.list[index] = payload;
		}
		return $profiles;
	});
});

appWindow.listen('SettingsProfileSelected', ({ payload }) => {
	SETTINGS_PROFILES.update($profiles => {
		$profiles.selected = payload;
		return $profiles;
	});
});