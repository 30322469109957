<script context="module">
	import { invoke } from '@tauri-apps/api/tauri';
	import { appWindow } from '@tauri-apps/api/window';
	import { onDestroy } from 'svelte';
	import { writable } from 'svelte/store';

	const LOCAL_IP_ADDRESS = writable(null);
	appWindow.listen('LocalIpAddress', ({ payload }) => {
		LOCAL_IP_ADDRESS.set(payload);
	});

	const RE_IPV4_ADDR_PORT =
		/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}:(?=.)((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
</script>

<script>
	export let showManualConnect;

	let manual_input;
	function connect_manual(e) {
		e.preventDefault();

		if (manual_input.value.match(RE_IPV4_ADDR_PORT)) {
			invoke('connect_device', { addr: manual_input.value });
		} else {
			alert('Invalid IP address');
		}
	}

	// Close on escape
	function escape(e) {
		if (e.key === 'Escape') showManualConnect(false);
	}
	addEventListener('keydown', escape);
	onDestroy(() => removeEventListener(escape));
</script>

<main>
	<header>
		<img alt="" src="img/network.svg" />
		Manual Connect
	</header>
	<section>
		<div class="local-ip">
			<code class="select-all">{$LOCAL_IP_ADDRESS ?? 'Unknown'}</code>
			<p>
				This is the IP address and port Beam thinks it's currently running on. On another computer, you should be able to enter this address
				in the below field to connect manually.
				<br />
				<br />
				If the IP address is wrong or you are having trouble connecting, it might be helpful to Beam for you to specify the desired network interface
				and port to run Beam on in Settings > Network.
				<br />
				<br />
				If you are trying to connect to a device that is not on the same network as this one, you'll need to enable "Allow Non-Local Connections"
				in Settings > Network.
			</p>
		</div>

		<form on:submit={connect_manual}>
			<input
				required
				title=""
				type="text"
				placeholder="192.168.0.{Math.round(Math.random() * 255)}:1337"
				bind:this={manual_input}
				pattern={'^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}:(?=.)((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$'} />
			<div class="input-focus-ring" />
			<button type="submit" class="connect-btn"><img src="img/connected.svg" alt="" />CONNECT</button>
		</form>
	</section>
</main>

<style>
	main {
		max-width: 42em;
	}
	main > header {
		padding: 1rem;
		font-size: 1.5em;
		font-weight: 600;
		background-color: #005cff;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	main > header > img {
		height: 1em;
		margin-right: 0.5rem;
	}
	main > section {
		padding: 1.5rem;
		padding-top: 1rem;
		font-size: 0.9em;
	}
	p {
		margin-bottom: 0;
		line-height: 1.6;
	}
	code {
		display: block;
		text-align: center;
		font-size: 3em;
		padding: 1rem;
	}
	.local-ip {
		margin-bottom: 2rem;
	}

	form {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 1em;
	}
	input {
		padding: 0;
		margin: 0;
		outline: none;
		border: none;
		background: none;
		color: inherit;
		font: inherit;
		border-radius: 0.4rem;
		padding: 0.65em;
		font-size: 0.95em;
		color: #fff;
		flex: 1;
		border: 1px solid #101010;
		border-radius: 0.4rem;
		background-color: #141418;
		box-shadow: inset 0 0 4px 0px rgb(0 0 0 / 20%);
		position: relative;
	}
	input,
	.input-focus-ring {
		grid-column: 1 / 2;
		grid-row: 1;
	}
	.input-focus-ring {
		pointer-events: none;
		width: 100%;
		height: 100%;
		box-shadow: inset 0 0 0px 2px #004b81;
		visibility: hidden;
		z-index: 1;
		border-radius: 0.4rem;
	}
	input:focus + .input-focus-ring {
		visibility: visible;
	}
	.connect-btn {
		padding: 0;
		margin: 0;
		outline: none;
		border: none;
		background: none;
		color: inherit;
		font: inherit;
		position: relative;
		padding: 0.8em;
		padding-left: 1.2em;
		padding-right: 1.2em;
		color: #fff;
		display: flex;
		align-items: center;
		font-size: 0.75em;
		border-radius: 0.4rem;
		opacity: 1 !important;
		z-index: 1;
		background-color: #222228;
		border: 1px solid #101010;
		cursor: pointer;
	}
	.connect-btn > img {
		height: 1.1em;
		margin-right: 0.65em;
	}
	input:valid ~ .connect-btn {
		background-color: #004b81;
	}
	input:valid ~ .connect-btn:hover {
		background-color: #0067b1;
	}
</style>
