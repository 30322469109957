<script context="module">
	import { ACTIVE_DEVICE_SRC_DISCOVERY, ACTIVE_DEVICE_SRC_FRIENDS } from './constants';

	const DISCOVERY_CLOSED = 0;
	const DISCOVERY_OPEN = 1;
	const DISCOVERY_NOFRIENDS = 2;
</script>

<script>
	import DiscoveryList from './DiscoveryList.svelte';
	import { FRIENDS, FRIENDS_STATUS } from '../friends';
	import DeviceListItem from './DeviceListItem.svelte';
	import { onDestroy } from 'svelte';
	import { PEERS } from '../discovery';
	import { writable } from 'svelte/store';
	import ManualConnect from './ManualConnect.svelte';
	import DeviceView from './device/DeviceView.svelte';

	let manualConnect = false;
	let discovery = $FRIENDS.length === 0 ? DISCOVERY_NOFRIENDS : DISCOVERY_CLOSED;
	let activeDevice = writable(null);

	function showDiscovery(show) {
		if (show) {
			if (discovery > DISCOVERY_CLOSED) return;
			discovery = DISCOVERY_OPEN;
		} else {
			if (discovery === DISCOVERY_CLOSED) return;
			discovery = $FRIENDS.length === 0 ? DISCOVERY_NOFRIENDS : DISCOVERY_CLOSED;
		}
		activeDevice.set(null);
	}

	function showManualConnect(show) {
		manualConnect = show;
	}

	function manualConnectBgClick(e) {
		if (e.target && e.target.id === 'manual-connect') {
			showManualConnect(false);
		}
	}

	// Make sure activeDevice reacts to changes in PEERS and FRIENDS
	onDestroy(
		FRIENDS_STATUS.subscribe(friends => {
			// If we have no friends, show the discovery list
			if (friends.length === 0) {
				discovery = DISCOVERY_NOFRIENDS;
				activeDevice.set(null);
				return;
			} else if (discovery === DISCOVERY_NOFRIENDS) {
				discovery = DISCOVERY_CLOSED;
				activeDevice.set(null);
				return;
			}

			const peers = $PEERS;
			activeDevice.update(activeDevice => {
				if (activeDevice == null) return null;

				// If this device was selected via friends, check if it's still in the list
				if (activeDevice.src === ACTIVE_DEVICE_SRC_FRIENDS) {
					activeDevice.device = friends.find(friend => friend.device.id === activeDevice.device.id)?.device;
					return activeDevice.device != null ? activeDevice : null;
				} else if (activeDevice.src === ACTIVE_DEVICE_SRC_DISCOVERY) {
					activeDevice.device = peers.find(peer => peer.device.id === activeDevice.device.id)?.device;
					return activeDevice.device != null ? activeDevice : null;
				}

				return activeDevice;
			});
		})
	);
</script>

<main>
	<div>
		<nav id="device-list" class:discovery-active={discovery > DISCOVERY_CLOSED}>
			<section id="known-devices">
				<section class="scroll-subtle">
					{#each $FRIENDS_STATUS as { device, status }}
						<DeviceListItem src={ACTIVE_DEVICE_SRC_FRIENDS} {device} {status} {activeDevice} />
					{/each}
				</section>

				<div class="add-device">
					<div class="pill-btn blue" on:click={() => showDiscovery(true)}>
						<img src="img/wifi.svg" alt="" />
						Add Device
					</div>
				</div>
			</section>

			<section id="discovery">
				<DiscoveryList {showDiscovery} {activeDevice} {showManualConnect} />
			</section>
		</nav>

		<section id="device-view">
			{#if $activeDevice}
				<DeviceView device={$activeDevice.device} />
			{:else}
				<div class="choose-device">
					<img src="img/devices.svg" alt="" />
					Choose a device to view details
				</div>
			{/if}
		</section>
	</div>

	<section id="manual-connect" class:active={manualConnect} on:click={manualConnectBgClick}>
		<div>
			<ManualConnect {showManualConnect} />
		</div>
	</section>
</main>

<style>
	main {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background: radial-gradient(75% 35% at 50% 105%, #1a1a25 20%, transparent 90%), linear-gradient(transparent 0%, #101014 80%);
	}
	main > div {
		display: grid;
		grid-template-columns: 14rem 1fr;
		gap: 1.5rem;
		width: 100%;
		height: 100%;
		padding: 1.5rem;
		position: relative;
		max-width: 70rem;
		max-height: 40rem;
	}

	#manual-connect {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.4);
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.5s cubic-bezier(0.22, 1, 0.36, 1);
		padding: 4rem;
	}
	#manual-connect > div {
		transform: scale(0);
		border-radius: 0.4rem;
		background-color: #1f1f29;
		box-shadow: 0 0 4px 0 #0c0c0f;
		transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1);
		overflow: hidden;
	}
	#manual-connect.active {
		opacity: 1;
		pointer-events: all;
	}
	#manual-connect.active > div {
		transform: scale(1);
	}

	#device-view {
		max-height: 100%;
		overflow: hidden;
		box-shadow: 0 0 4px 0 #0c0c0f;
		border-radius: 0.4rem;
	}

	#device-list {
		background-color: #1f1f29;
		box-shadow: 0 0 4px 0 #0c0c0f;
		border-radius: 0.4rem;
		height: 100%;
		position: relative;
		overflow: hidden;
		transform: translateZ(0); /* fix for safari */
	}
	#device-list > * {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	#device-list > #known-devices {
		padding: 1rem;
		transition: opacity 0.5s;
		transition-timing-function: cubic-bezier(0.64, 0, 0.78, 0);
	}
	#device-list > #discovery {
		background-color: #1f1f29;
		transition-property: transform, opacity;
		transition-duration: 0.5s;
		transition-timing-function: cubic-bezier(0.64, 0, 0.78, 0);
		transform: translateY(100%);
		pointer-events: none;
		z-index: 2;
		opacity: 0;
	}
	#device-list.discovery-active > #discovery {
		transform: translateY(0%);
		pointer-events: all;
		opacity: 1;
		transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
	}
	#device-list.discovery-active > #known-devices {
		opacity: 0;
		pointer-events: none;
		transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
	}

	.choose-device {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100%;
		width: 100%;
		padding: 1rem;
		text-align: center;
		background-color: #1f1f29;
		box-shadow: 0 0 4px 0 #0c0c0f;
		border-radius: 0.4rem;
	}
	.choose-device > img {
		height: 3rem;
		margin-bottom: 1rem;
	}

	#known-devices {
		display: flex;
		flex-direction: column;
	}
	#known-devices > .scroll-subtle {
		flex: 1;
	}
	#known-devices > .add-device {
		margin-top: 1rem;
	}
</style>
