<script context="module">
	import { writable } from 'svelte/store';

	export const SIDEBAR_WORKSPACE = 0;
	export const SIDEBAR_NETWORK = 1;
	export const SIDEBAR_SETTINGS = 2;
	export const SIDEBAR_HELP = 3;

	export const SIDEBAR_SELECTED = writable(SIDEBAR_WORKSPACE);
</script>

<div id="sidebar">
	<nav>
		<div class="sidebar-logo">
			<img draggable="false" alt="" src="img/logo.svg" />
		</div>
		<div class="sidebar-btn" on:click={() => SIDEBAR_SELECTED.set(SIDEBAR_WORKSPACE)} class:active={$SIDEBAR_SELECTED == SIDEBAR_WORKSPACE}>
			<img draggable="false" src="img/layout.svg" alt="" />
			Workspace
		</div>
		<div class="sidebar-btn" on:click={() => SIDEBAR_SELECTED.set(SIDEBAR_NETWORK)} class:active={$SIDEBAR_SELECTED == SIDEBAR_NETWORK}>
			<img draggable="false" src="img/devices.svg" alt="" />
			Network
		</div>
		<div class="sidebar-btn" on:click={() => SIDEBAR_SELECTED.set(SIDEBAR_SETTINGS)} class:active={$SIDEBAR_SELECTED == SIDEBAR_SETTINGS}>
			<img draggable="false" src="img/settings.svg" alt="" />
			Settings
		</div>
		<div class="sidebar-btn" on:click={() => SIDEBAR_SELECTED.set(SIDEBAR_HELP)} class:active={$SIDEBAR_SELECTED == SIDEBAR_HELP}>
			<img draggable="false" src="img/help.svg" alt="" />
			Help
		</div>
	</nav>

	<section id="meta">Beam v1.0.0</section>
</div>

<style>
	#sidebar {
		height: 100%;
		background-color: #1c1c24;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
		z-index: 996;

		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}

	nav {
		display: grid;
		grid-template-rows: auto auto auto;
		margin-top: auto;
	}
	.sidebar-btn {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 1rem;
	}
	.sidebar-btn:last-child {
		padding-bottom: 0;
	}
	.sidebar-btn > img {
		display: block;
		height: 1.5rem;
		margin-bottom: 0.8rem;
	}

	.sidebar-btn {
		color: #7a7a7a;
		transition: color 0.33s cubic-bezier(0.34, 1, 0.64, 1);
	}
	.sidebar-btn > img {
		transform: scale(1);
		filter: brightness(0.47);
		transition: transform 0.33s cubic-bezier(0.34, 3, 0.64, 1), filter 0.33s cubic-bezier(0.34, 1, 0.64, 1);
	}
	.sidebar-btn:hover,
	.sidebar-btn.active {
		color: #fff;
	}
	.sidebar-btn:hover > img,
	.sidebar-btn.active > img {
		filter: brightness(1);
	}
	.sidebar-btn:not(.active):hover > img {
		transform: scale(1.05);
	}
	.sidebar-btn:active > img {
		transform: scale(0.95) !important;
	}

	#meta {
		text-align: center;
		font-size: 0.8em;
		color: #7a7a7a;
		margin-top: auto;
		padding: 1rem;

		user-select: initial;
		-moz-user-select: initial;
		-webkit-user-select: initial;
		-ms-user-select: initial;
	}

	.sidebar-logo {
		text-align: center;
		margin-bottom: 1rem;
	}
	.sidebar-logo > img {
		width: 2rem;
		height: 2rem;
	}
</style>
