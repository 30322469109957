<script>
	import categories from './categories';
	import { SETTINGS_SCHEMA } from './settings';
	import Setting from './Setting.svelte';

	let active = Object.keys(categories)[0];
</script>

<main>
	<nav>
		<div class="categories">
			{#each Object.entries(categories) as [key, category]}
				<div
					class="category"
					class:active={active === key}
					on:mousedown={e => {
						if (e.button === 0) active = key;
					}}>
					{#if typeof category.icon === 'string'}
						<img src={category.icon} alt="" />
					{:else}
						<img src={category.icon.src} class:black={!!category.icon.black} alt="" />
					{/if}
					<span>{category.name}</span>
				</div>
			{/each}
		</div>
	</nav>
	<article>
		<div>
			{#key /* needed to fix race condition */ active}
				{#if active && active in categories}
					{#if categories[active].component != null}
						<svelte:component this={categories[active].component} />
					{:else if active in $SETTINGS_SCHEMA}
						{#each $SETTINGS_SCHEMA[active] as setting}
							{#if !setting.hidden}
								<Setting {setting} />
							{/if}
						{/each}
					{/if}
				{/if}
			{/key}
		</div>
	</article>
</main>

<style>
	main {
		display: flex;
		width: 100%;
		height: 100%;
	}
	main > nav {
		display: flex;
		flex-direction: column;
		background-color: #16161c;
		height: 100%;
		overflow-y: auto;
		user-select: none;
		-webkit-user-select: none;
		box-shadow: 0 0 4px 0 #0c0c0f;
		min-width: 11.5rem;
	}
	main > article {
		flex: 1;
		padding: 1.5rem;
		overflow-y: auto;
	}
	main > article > div {
		max-width: 35em;
	}
	.category {
		padding: 0.4rem;
		padding-left: 0.7rem;
		padding-right: 0.7rem;
		border-radius: 0.4rem;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.category > img {
		margin-right: 0.5rem;
		width: 1em;
		height: 1em;
	}
	.category > img.black {
		filter: invert(1);
	}
	.category.active,
	.category:active {
		background-color: #006aff;
	}
	.category:not(:last-child) {
		margin-bottom: 1rem;
	}

	.categories {
		flex: 1;
		padding: 1rem;
	}
</style>
