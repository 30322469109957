<script>
	import Tick from './icons/Tick.svelte';
	import { tippy } from './tippy';

	export let icon;
	export let click = () => {};
	export let tooltip = null;
	export let disabled = false;

	let click_animations = false;

	$: {
		if (disabled) click_animations = false;
	}
</script>

<div
	class="toolbar-btn"
	class:disabled
	class:click={click_animations}
	on:click={() => {
		if (disabled) return;
		click_animations = true;
		click();
	}}
	use:tippy={tooltip}>
	<div>
		<svelte:component this={icon} />
		<slot />
	</div>
	<div class="whiteout">
		<svelte:component this={icon} />
		<slot />
	</div>
	<div class="wipeout">
		<Tick />
		<slot />
	</div>
</div>

<style>
	.toolbar-btn {
		position: relative;
		text-transform: uppercase;
		border-radius: 4px;
		box-shadow: inset 0 0 0 1px #8f8f9a;
		display: inline-block;
		padding: 0.4em;
		padding-left: 0.8em;
		padding-right: 0.8em;
		color: #8f8f9a;
		width: 100%;
		backdrop-filter: blur(5px);
		cursor: pointer;
		transition-property: color, box-shadow;
		transition-duration: 0.5s;
		transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
		text-shadow: none;
		overflow: hidden;
	}
	.toolbar-btn > div {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.toolbar-btn:not(.disabled):hover {
		box-shadow: inset 0 0 0 1px #fff;
		color: #fff;
	}
	.toolbar-btn:not(.disabled):active {
		transition: none;
		background-color: #fff;
		backdrop-filter: none;
		color: #000;
	}
	.toolbar-btn :global(svg) {
		height: 0.9em;
		margin-right: 0.5em;
		fill: currentcolor;
	}
	.toolbar-btn > .wipeout {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		color: #000;
		background-color: #31bc6f;
		clip-path: inset(0% 0% 100% 0%);
	}
	.toolbar-btn > .whiteout {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		color: #000;
		clip-path: inset(0% 0% 100% 0%);
		background: linear-gradient(#fff, #fff);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
	.toolbar-btn:not(.disabled).click:not(:active) > .wipeout {
		animation-name: toolbar-btn-click-wipeout;
		animation-duration: 1s;
	}
	.toolbar-btn:not(.disabled).click:not(:active) > .whiteout {
		animation-name: toolbar-btn-click-whiteout;
		animation-duration: 1s;
	}

	.toolbar-btn.disabled {
		cursor: default !important;
		opacity: 0.2 !important;
	}

	@keyframes toolbar-btn-click-wipeout {
		0% {
			clip-path: inset(100% 0% 0% 0%);
		}
		50% {
			clip-path: inset(0% 0% 0% 0%);
		}
		100% {
			clip-path: inset(0% 0% 100% 0%);
		}
	}
	@keyframes toolbar-btn-click-whiteout {
		0% {
			clip-path: inset(0% 0% 0% 0%);
			background-size: 100% 100%;
		}
		50% {
			clip-path: inset(0% 0% 0% 0%);
			background-size: 100% 0%;
		}
		100% {
			clip-path: inset(0% 0% 100% 0%);
			background-size: 100% 0%;
		}
	}
</style>
