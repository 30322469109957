import state from './state.json';
import { mockIPC } from '@tauri-apps/api/mocks';
import { SETTINGS, SETTINGS_PROFILES } from '../settings/settingsState';
import { get } from 'svelte/store';

const demo = !('__TAURI_METADATA__' in window);

let eventHandlers;

function emitEvent(event, payload) {
	if (!eventHandlers || !(event in eventHandlers)) return;
	eventHandlers[event]({ payload });
}

function demoIPC() {
	eventHandlers = {};

	const cmdHandlers = {
		tauri: args => {
			if (args.message.cmd === 'listen') {
				eventHandlers[args.message.event] = window[`_${args.message.handler}`];
			}
		},

		identify_monitors: () => {
			emitEvent('IdentifyMonitors');
		},

		update_setting: ({ key, value }) => {
			const settings = get(SETTINGS);
			settings[key] = JSON.parse(value);
			SETTINGS_PROFILES.update(profiles => profiles);
		},
	};

	mockIPC((cmd, args) => {
		const handler = cmdHandlers[cmd];
		if (handler) {
			return handler(args);
		}
	});
}
if (demo) {
	demoIPC();
}

export function ready() {
	if (!demo) return;

	state.forEach(({ name, payload }) => {
		if (!(name in eventHandlers)) {
			throw new Error(`Event ${name} not handled by demo IPC`);
		}
		eventHandlers[name]({ payload });
	});
}